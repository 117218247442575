<template>
  <div class="layout" id="comments">
    <van-form style="margin-top: 16px" @submit="onSubmit">
      <van-cell-group inset class="comment-cell-group">
        <van-field
          v-model="state.type"
          readonly
          clickable
          name="type"
          label="投诉类型"
          placeholder="点击选择投诉类型"
          @click="showPicker = true"
          :rules="[{ required: true, message: '请选择投诉类型' }]"
        />
        <van-popup v-model:show="showPicker" position="bottom" teleport="body">
          <van-picker
            title="投诉类型"
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
      </van-cell-group>
      <van-cell-group inset class="comment-cell-group" style="margin-top: 16px">
        <van-field
          v-model="state.comments"
          rows="3"
          autosize
          name="comments"
          type="textarea"
          maxlength="200"
          placeholder="请详细填写您遇到的问题，以便尽快为您核实处理"
          show-word-limit
          :rules="[{ required: true, message: '请先写您遇到的问题' }]"
        />
      </van-cell-group>
      <van-cell-group inset class="comment-cell-group" style="margin-top: 16px">
        <van-field
          v-model="state.nickname"
          name="nickname"
          label="投诉人"
          placeholder="投诉人（选填）"
        />
        <van-field
          v-model="state.mobile"
          name="mobile"
          label="联系电话"
          placeholder="联系电话（选填）"
        />
      </van-cell-group>

      <div style="margin: 16px">
        <van-button block type="primary" style="letter-spacing: 15px" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { Notify } from "vant";
import { getComments } from "../api";

const route = useRoute();

const siteCode = localStorage.getItem("siteCode") || route.query.site_code;
const tankerName =
  localStorage.getItem("tankerName") || route.query.tanker_name;

const state = reactive({
  siteCode,
  tankerName,
  type: "",
  comments: "",
  nickname: "",
  mobile: "",
});
const initData = () => {
  setTimeout(() => {
    state.type = "";
    state.comments = "";
    state.nickname = "";
    state.mobile = "";
  }, 500);
};

const onSubmit = async (values) => {
  console.log(values);
  const res = await getComments({
    ...state,
    ...values,
  });
  console.log(res);
  if (res.status === 0) {
    Notify({ type: "success", message: "投诉成功" });
    initData();
  } else {
    Notify({ type: "error", message: "投诉失败" });
  }
};

const showPicker = ref(false);

const onConfirm = (value) => {
  state.type = value;
  showPicker.value = false;
};
const columns = ["油品质量", "计量问题", "发票问题", "其他"];
</script>

<style scoped>
.layout {
  position: relative;
  width: 100vw;
  min-height: calc(100vh - 120px);
}

.comment-cell-group {
  box-shadow: 0px 12px 18px 0px rgba(214, 214, 214, 0.2);
  border-radius: 0;
}
</style>