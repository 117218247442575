import { createRouter, createWebHistory } from "vue-router";
import Home from "../Home.vue";
import TraceSearch from "../Views/TraceSearch.vue";

const routes = [
    {
        path: "/",
        component: Home,
    },
    {
        path: "/search",
        component: TraceSearch,
    },
    {
        path: "/result",
        component: () => import("../Views/Result.vue"),
    },
    {
        path: "/500",
        component: () => import("../Views/ServerError.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
