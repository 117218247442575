<template>
  <router-view></router-view>
</template>

<script setup>
document.title = '成品油溯源码'
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aliceblue;
  min-height: 100vh;
}
</style>
