import request from "../utils/request";

export function getOilTrace(data) {
    return request({
        url: "/api/oilStation/traceCode",
        method: "post",
        data,
    });
}

export function getMachineCheck(data) {
    return request({
        url: "/admin/api/checkRecord",
        params: data,
    });
}

export function getComments(data) {
    return request({
        url: "/admin/api/opinion",
        method: "post",
        data,
    });
}
